.popupContainer {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  background-color: #1C528A;
  opacity: 0.95;
  z-index: 10;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 10px;
}

.closeContainer {
  align-self: flex-end;
}

.closeButton {
  width: 40px;
  margin: 10px;
}

.popupList {
  display: grid;
  row-gap: 50px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  height: 80%;
  align-items: center;
}
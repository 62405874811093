.imagestyle {
  width: 100%;
  max-width: 500px;
  max-height: 250px;
}

.projectContainer {
  width: 80vw;
  max-width: 1000px;
  margin-bottom: 50px;
  z-index: 2;
  scroll-margin-top: 100px;
}

.mainHeader {
  text-align: center;
  margin: 50px;
  font-size: 50px;
}

.description {
  margin: 40px 20px;
  font-size: 20px;
  font-weight: bold;
}

.shortdescription {
  margin: 40px 20px;
  font-size: 20px;
  font-weight: bold;
}

.projectTitleFront {
  margin: 10px;
}

.projectTitleBack {
  font-size: 40px;
}

.flipCard, .clickFlip {
  background-color: transparent;
  width: 100%;
  height: 60vh;
  border: 1px solid #fff;
  border-radius: 10px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin: 10px;
}

/* This container is needed to position the front and back side */
.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.clickFlip .flipCardInner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flipCardFront, .flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flipCardFront {
  background-color: rgb(247, 247, 247);
  color: black;
}
  
/* Style the back side */
.flipCardBack {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #1C528A;
  color: white;
  transform: rotateY(180deg);
}

.projectButton {
  width: 60%;
  height: 45px;
  font-size: 1em;
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .cardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
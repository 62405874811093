.introContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80vw;
  height: 65vh;
  padding-bottom: 18vh;
  max-width: 1000px;
  margin-top: 17vh;
  z-index: 1;
  scroll-margin-top: 150px;
  position: relative;
}

.title {
  margin-bottom: 15px;
  font-size: 60px;
  text-align: center;
}

.introduction {
  font-size: 20px;
}

.socialIconscontainer {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 5px;
  justify-items: center;
  width: 100%;
}

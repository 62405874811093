.mainHeader {
  text-align: center;
  margin: 50px;
  font-size: 50px;
}

.aboutContainer {
  width: 80vw;
  max-width: 1000px;
  margin-bottom: 50px;
  z-index: 3;
  scroll-margin-top: 100px;
}

.intro {
  font-size: 20px;
}

.resumeButton {
  width: 100%;
  margin: 20px 0;
  background-color: #fff;
  color: #1C528A;
  border: solid 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;  
}

.resumeButton:active {
  color: #fff;
  background-color: #1C528A;
}

.aboutInfo {
  display: grid;
  gap: 10px;
}

.languageContainer, .frameworkContainer, .skillContainer {
  color: white;
  width: 100%;
  height: 40vh;
  border-radius: 10px;
  display: grid;
}

h3 {
  margin-left: 30px;
  align-self: center;
  font-size: 35px;
}

.list {
  margin-left: 30px;
}

.list li {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
}

.languageContainer {
  background-color: #1C528A;
}

.frameworkContainer {
  background-color: #E2505C;
}

.skillContainer {
  background-color: #FEBF52;
}

@media only screen and (min-width: 768px) {
  .aboutInfo {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1100px) {
  .aboutInfo {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
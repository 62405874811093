.mainHeader {
  text-align: center;
  margin: 50px;
  font-size: 50px;
}

.contactContainer {
  width: 80vw;
  max-width: 1000px;
  margin-bottom: 30px;
  z-index: 4;
  scroll-margin-top: 100px;
}

.formContainer {
  width: 100%;
}

.contactForm {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

}

.nameInput, .emailInput, .textInput {
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 35px;
  background-color: rgb(247, 247, 247);
  border: solid 1px;
  border-radius: 10px;
  max-height: 35vh;
}

::placeholder {
  margin: 10px;
}

.contactButton {
  width: 100%;
  margin: 20px 0;
  background-color: #fff;
  color: #1C528A;
  border: solid 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;  
}

.contactButton:active {
  color: #fff;
  background-color: #1C528A;
}

.formSubmissionSuccess {
  display: block;
  color: #0aa104;
  font-size: 20px;
  font-weight: bold;
  border: solid 1px;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  background-color: #09a1042c;
}

.formSubmissionError {
  display: block;
  color: #a10404;
  font-size: 20px;
  font-weight: bold;
  border: solid 1px;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  background-color: #a104042c;
}

.nothing {
  display: none;
}
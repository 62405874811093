.mainContainer {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: grid;
  justify-items: center;
}



.mainContainer::after {
  content: '';
  position: absolute;
  background-image: url(../../assets/images/background.png);
  inset: 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: -4px 8vh;
  opacity: 0.2;
  filter: blur(5px);
}
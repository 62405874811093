.footerContainer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100vw;
  border-top: solid 1px;
  height: 93px;
  max-width: 98vw;
}

.socialIconscontainer {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}
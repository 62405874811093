*{
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.navcontainer {
  position: fixed;
  left: 0;
  font-weight: bold;
  width: 100vw;
  background-color: white;
  z-index: 8;
}

.navList{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px ;
  border-bottom: solid 1px rgba(0, 0, 0, 0.233);
  box-shadow: 0px 3px 5px 1px #ccc;
}

.burgerMenu {
  max-width: 30px;
  cursor: pointer;
}

.logo {
  max-width: 70px;
  cursor: pointer;
}

.computerMenu {
  padding: 10px;
}

.computerMenu:hover {
  border: solid 1px #1C528A;
  padding: 9px;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  .mobileMenu {
    display: none;
  }
  
  .sectionActive {
    background-color: #1C528A;
    color: white;
    border-radius: 10px;
    padding: 9px;
  }

}

@media only screen and (max-width: 768px) {
  .computerMenu, .sectionActive {
    display: none;
  }

}
